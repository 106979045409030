/* CONTENEDORES DE REACT-LIVE */
.content-formato-factura {
    display: block !important;
}
.live-editor {
    min-height: 400px;
    padding: 15px !important;
    margin: 20px 40px 30px 0 !important;
}

/* CONTENEDORES DE REACT-LIVE */
.item-data {
    flex:1;
    padding: 0 15px;
}

.item-data-numbers {
    flex:1;
    text-align: right;
    padding: 0 15px;
}

.print-shadow-factura {
    background-color: white;
    position: relative;
    padding: 1cm 1.5cm;
    -webkit-print-color-adjust: exact;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.font-arial{
    font-family: Arial;
}

/* FLEX ROW CENTER */
.content-row-center-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.content-row-center-end {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.content-row-center-start {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

/* FLEX ROW END */
.content-row-end-start {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
}

.content-row-end-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.content-row-end-end {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

/* FLEX ROW START */
.content-row-start-start {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.content-row-start-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.content-row-start-end {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
}

.content-row-between-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.content-row-between-start {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
