@import "../../node_modules/react-select/dist/react-select.css";
@import "../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
@import "../../node_modules/react-dropdown-advanced/styles/rdropdown.css";
@import "../../node_modules/react-day-picker/lib/style.css";
@import "../../node_modules/react-checkbox-tree/lib/react-checkbox-tree";

@import "configs/color";
@import "_custom_styles_btn";
@import "bootstrap_reset.css";
@import "_custom_global_styles";
@import "_utils";
@import "_custom_styles_controls";
@import "_scrollbar.css";
@import "_custom_factura";
@import "_custom_tab";
@import "_custom_editor_wysiwyg";
@import "_pagination";
/*NODE MODULES CSS*/


@import "impresion";
html {
    font-size: 1rem;
}
.container {
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    font-size: 16px;
    margin-left: 60px;
    margin-right: 60px;
}

/*Style preloader*/

.loader-container {
    min-height: 140px;
}

.loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 60px;
    height: 60px;
    left: 50%;
    background-color: #e0e0e0;
    position: absolute;
    margin: 1rem 1rem 1rem -30px;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite;
    /* Safari */
    animation: spin 1.5s linear infinite;
}

.small-loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 30px;
    height: 30px;
    background-color: #e0e0e0;
    position: absolute;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite;
    /* Safari */
    animation: spin 1.5s linear infinite;
    margin: 1rem 1rem 1rem -30px;
    left: 40%;
}

/* Safari */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .topnavbar {
        min-height: 43px;
        padding: 0.2rem 0.5rem 0 0.5rem !important;
    }
    .content-wrapper {
        top: 44px;
    }
}
@media only screen and (max-width: 767px) {
    .container {
        margin-left: 0;
        margin-right: 0;
    }
    .sidebar {
        display: none !important;
    }
    .content-wrapper {
        left: 0 !important;
        width: 100%;
        padding: 20px 0 0;
        top: 53px;
    }
}
