.punto {
    cursor: pointer;
    width: 15px;
    height: 15px;
    background-color: #CCCCCC;
    border-radius: 50%;
    margin-left: 10px;
}
.punto:first-child {
    margin-left: 0;
}

.punto:after {
    content: "";
    width: 12px;
    height: 4px;
    background-color: #CCCCCC;
    position: absolute;
    margin-left: -11px;
    margin-top: 4.5px;
}
.punto:first-child:after {
    width: 0px;
    margin-left: 0;
}

.punto-activo {
    background-color: #FFA26B;
}
.punto-activo:after {
    content: "";
    width: 12px;
    height: 4px;
    background-color: #FFA26B;
    position: absolute;
    margin-left: -11px;
    margin-top: 4.5px;
}
