@import "./configs/color.css";
.btn {
    border-radius: 1.1rem;
    font-weight: 700;
    font-size: 0.8rem;
}

.btn:focus,
.btn.focus {
    box-shadow: 0 0 0 0.2rem transparent;
}
.btn img {
    max-width: 15px;
    margin-right: 1em;
}

/* boton primario */

.btn-primary {
    background-color: $primary;
    border: none;
    box-shadow: -2px 3px 0 0 $primary2 !important;
    color: $negro-dark;
}

.btn-primary:hover {
    background-color: $primary2;
    color: $negro-dark;
}

.btn-primary:disabled {
    cursor: not-allowed;
    background-color: $primary;
    color: $negro-dark;
    opacity: 0.7;
}
.btn-outline-primary:disabled {
    cursor: not-allowed;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    background-color: $primary2;
    box-shadow: 0px 0px 0 0 $primary !important;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
    color: $negro-dark;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    /* border-color: #FF9639; */
    background-color: $primary2;
    box-shadow: 0px 0px 0 0 $primary !important;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
    color: $negro-dark;
}

.btn-outline-primary {
    box-shadow: -2px 3px 0 0 $primary2;
    background-color: #fff !important;
    color: $primary !important;
    border-color: $primary;
}

.btn-outline-primary:hover {
    border-color: $primary2;
    color: $primary2 !important;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    box-shadow: -1px -3px 0 0 $primary;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: -1px -3px 0 0 $primary;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-warning {
    color: white;
    background-color: #e3a630;
    border: none;
    box-shadow: -2px 3px 0 0 #ce982f;
}

.btn-warning:not(:disabled):not(.disabled):hover,
.btn-warning:not(:disabled):not(.disabled).hover,
.show > .btn-warning.dropdown-toggle {
    background-color: #af822c;
    transition: top 0.2s ease-in-out;
    color: white;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
    background-color: #ce982f;
    box-shadow: -1px -3px 0 0 #dda531;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
    border-color: #ff9639;
    background-color: #ce982f;
    box-shadow: -1px -3px 0 0 #dda531;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

/* boton danger */
.btn-danger {
    color: white;
    background-color: #d96666;
    border: none;
    box-shadow: -2px 3px 0 0 #a85454;
}
.btn-danger:disabled {
    cursor: not-allowed;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
    background-color: #c76262;
    box-shadow: -1px -3px 0 0 #a85454;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    border-color: #d85535;
    background-color: #c76262;
    box-shadow: -1px -3px 0 0 #a85454;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}
/* boton con solo texto primario */
.btn-text-primary {
    background-color: transparent !important;
    border: none;
    color: $primary2;
}

.btn-text-primary:hover {
    color: #0069d9;
}

.btn-text-primary:focus {
    box-shadow: 0 0 0 0 transparent !important;
}

/* boton secundario */

.btn-secondary {
    background-color: $secondary;
    border: none;
    box-shadow: -2px 3px 0 0 $secondary2 !important;
}

.btn-secondary:hover {
    background-color: $secondary2;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
    cursor: not-allowed;
    background-color: $secondary;
    opacity: 0.7;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    background-color: $secondary2;
    box-shadow: 0px 0px 0 0 $secondary !important;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    /* border-color: #FF9639; */
    background-color: $secondary2;
    box-shadow: 0px 0px 0 0 $secondary !important;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

/* a espera de estilos finales para el focus del boton */

.btn-secondary:focus,
.btn-secondary:active:focus {
    /* border: 2px solid #FF9639; */
}

.btn-text-secondary {
    background-color: transparent !important;
    border: none;
    color: $secondary;
}

.btn-text-secondary:hover {
    color: $secondary2;
}

.btn-text-secondary:focus {
    box-shadow: 0 0 0 0 transparent !important;
}

.btn-outline-secondary {
    box-shadow: -2px 3px 0 0 $secondary2;
    background-color: #fff !important;
    color: $secondary !important;
    border-color: $secondary;
}

.btn-outline-secondary:disabled {
    cursor: not-allowed;
}

.btn-outline-secondary:hover {
    border-color: $secondary2;
    color: $secondary2 !important;
    background-color: #eff3f6 !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
    box-shadow: -1px -3px 0 0 $secondary;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: -1px -3px 0 0 $secondary;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

/* boton tertiary */
.btn-tertiary {
    color: white;
    background-color: $negro-dark2;
    border: none;
    box-shadow: -2px 3px 0 0 $negro-dark;
}
.btn-tertiary:hover {
    background-color: $negro-dark;
    color: $blanco;
}
.btn-tertiary.disabled,
.btn-tertiary:disabled {
    cursor: not-allowed;
    background-color: $negro-dark2;
    opacity: 0.7;
}
.btn-tertiary:not(:disabled):not(.disabled):active,
.btn-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-tertiary.dropdown-toggle {
    background-color: $negro-dark2;
    box-shadow: -1px -3px 0 0 $negro-dark;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-tertiary:not(:disabled):not(.disabled):active:focus,
.btn-tertiary:not(:disabled):not(.disabled).active:focus,
.show > .btn-tertiary.dropdown-toggle:focus {
    border-color: $negro-dark2;
    background-color: $negro-dark2;
    box-shadow: -1px -3px 0 0 $negro-dark;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-outline-tertiary {
    box-shadow: -2px 3px 0 0 $negro-dark;
    background-color: #fff !important;
    color: $negro-dark2 !important;
    border-color: $negro-dark2;
}

.btn-outline-tertiary:disabled {
    cursor: not-allowed;
}

.btn-outline-tertiary:hover {
    border-color: $negro-dark;
    color: $negro-dark !important;
    background-color: #eff3f6 !important;
}

.btn-outline-tertiary:not(:disabled):not(.disabled):active,
.btn-outline-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-outline-tertiary.dropdown-toggle {
    box-shadow: -1px -3px 0 0 $negro-dark;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-outline-tertiary:not(:disabled):not(.disabled):active:focus,
.btn-outline-tertiary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-tertiary.dropdown-toggle:focus {
    box-shadow: -1px -3px 0 0 $negro-dark;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

/* BTN PRIMARY OUTLINE */

.btn-primary-outline {
    background-color: #fff;
    color: #2e8edc;
    border: 1px solid #2e8edc;
    box-shadow: -2px 3px 0 0 #2e8edc;
}

.btn-primary-outline:not(:disabled):not(.disabled):active,
.btn-primary-outline:not(:disabled):not(.disabled).active,
.show > .btn-primary-outline.dropdown-toggle {
    background-color: $primary2;
    box-shadow: 0px 0px 0 0 $primary !important;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-primary-outline:not(:disabled):not(.disabled):active:focus,
.btn-primary-outline:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-outline.dropdown-toggle:focus {
    /* border-color: #FF9639; */
    background-color: $primary2;
    box-shadow: 0px 0px 0 0 $primary !important;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-gris-outline {
    background-color: #fff;
    color: $negro;
    border: 1px solid #a5a5a5;
    box-shadow: -2px 3px 0 0 #a5a5a5;
}

.btn-gris-outline:not(:disabled):not(.disabled):active,
.btn-gris-outline:not(:disabled):not(.disabled).active,
.show > .btn-gris-outline.dropdown-toggle {
    /* background-color: $primary2; */
    box-shadow: 0.5px 0.5px 0 0 $negro !important;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-gris-outline:not(:disabled):not(.disabled):active:focus,
.btn-gris-outline:not(:disabled):not(.disabled).active:focus,
.show > .btn-gris-outline.dropdown-toggle:focus {
    /* border-color: #FF9639; */
    /* background-color: $primary2; */
    box-shadow: 0.5px 0.5px 0 0 $negro !important;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-outline-primary:not(:disabled):not(.disabled):hover,
.btn-outline-primary:not(:disabled):not(.disabled).hover,
.show > .btn-outline-primary.dropdown-toggle {
    background-color: $gris !important;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    background-color: $gris !important;
}

/* boton naranja */
.btn-naranja {
    color: white;
    background-color: #fe7b51;
    border: none;
    box-shadow: -2px 3px 0 0 #c05938;
}

.btn-naranja:not(:disabled):not(.disabled):active,
.btn-naranja:not(:disabled):not(.disabled).active,
.show > .btn-naranja.dropdown-toggle {
    background-color: #c76262;
    box-shadow: -1px -3px 0 0 #c05938;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-naranja:hover {
    border-color: #d85535;
    background-color: #e0704e;
    /* box-shadow: -1px -3px 0 0 #C05938; */
    position: relative;
    /* top: 3px; */
    /* left: -2px; */
    /* transition: top 0.2s ease-in-out; */
    color: #fff;
}
/* boton naranja */
/* boton cerrar modal */
.btn-cerrar-modal {
    color: white;
    background-color: #fe7b51;
    border: none;
    /* box-shadow: -2px 3px 0 0 #C05938; */
}

.btn-cerrar-modal:not(:disabled):not(.disabled):active,
.btn-cerrar-modal:not(:disabled):not(.disabled).active,
.show > .btn-cerrar-modal.dropdown-toggle {
    background-color: #c76262;
    /* box-shadow: -1px -3px 0 0 #C05938; */
    position: relative;
    /* top: 3px;
    left: -2px; */
    transition: top 0.2s ease-in-out;
}

.btn-cerrar-modal:hover {
    border-color: #d85535;
    background-color: #e0704e;
    /* box-shadow: -1px -3px 0 0 #C05938; */
    position: relative;
    /* top: 3px; */
    /* left: -2px; */
    /* transition: top 0.2s ease-in-out; */
    color: #fff;
}
.btn-ayuda {
    position: relative;
    border-radius: 50%;
    color: white;
    background-color: #4d94cf;
    border: none;
}

.btn-ayuda:not(:disabled):not(.disabled):active,
.btn-ayuda:not(:disabled):not(.disabled).active,
.show > .btn-ayuda.dropdown-toggle {
    background-color: #256eab;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-ayuda:hover {
    border-color: #256eab;
    background-color: #256eab;
    position: relative;
    color: #fff;
}

.btn-ayuda-flotante {
    margin-left: 5px;
}

.btn-outline-naranja {
    box-shadow: -2px 3px 0 0 #d85535;
    color: #d85535 !important;
}

.btn-azul-oscuro {
    color: white;
    background-color: #256fab;
    border: none;
    box-shadow: -2px 3px 0 0 #1c4c73;
}

.btn-azul-oscuro:not(:disabled):not(.disabled):active,
.btn-azul-oscuro:not(:disabled):not(.disabled).active,
.show > .btn-azul-oscuro.dropdown-toggle {
    background-color: #1c4c73;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-azul-oscuro:hover {
    border-color: #1c4c73;
    background-color: #1c4c73;
    position: relative;
    color: #fff;
}

.btn-outline-naranja {
    box-shadow: -2px 3px 0 0 #d85535;
    color: #d85535 !important;
}

.btn-outline-naranja:not(:disabled):not(.disabled):active,
.btn-outline-naranja:not(:disabled):not(.disabled).active,
.show > .btn-outline-naranja.dropdown-toggle {
    box-shadow: -1px -3px 0 0 #c05938;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
    color: #c05938;
}

.btn-outline-naranja:not(:disabled):not(.disabled):active:focus,
.btn-outline-naranja:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-naranja.dropdown-toggle:focus {
    box-shadow: -1px -3px 0 0 #c05938;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
    color: #c05938;
}

.btn-swal-fracciones {
    background-color: #efefef !important;
    color: #418cc8 !important;
    font-weight: bold !important;
    border-radius: 10px !important;
    padding: 13px !important;
    box-shadow: 0.5px 2px #418cc8 !important;
    border: 1.5px solid #418cc8 !important;
    max-width: 190px;
}

/* btn field file */
.btn-field-file {
    border-radius: 5px;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* boton naranja */
.btn-naranja-drop {
    color: $negro-dark2;
    background-color: $primary;
    border: none;
    box-shadow: -2px 3px 0 0 $primary2;
}

.btn-naranja-drop:not(:disabled):not(.disabled):active,
.btn-naranja-drop:not(:disabled):not(.disabled).active,
.show > .btn-naranja-drop.dropdown-toggle {
    background-color: $primary2;
    box-shadow: -2px -3px 0 0 $primary2;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-naranja-drop:hover {
    border-color: $primary2;
    background-color: $primary2;
    /* position: relative; */
    color: #fff;
}

.btn-ayuda {
    .show {
        top: -13px !important;
        right: 32px !important;
        font-weight: normal;
        z-index: 2000;
        width: 280px;
    }
    .dda-container .dda-dropdown-list {
        max-width: 280px;
    }
    .dda-container:after {
        width: 0;
        height: 0;
        border-top: 50px solid transparent;
        border-left: 100px solid red;
        border-bottom: 50px solid transparent;
    }
    .dda-container .dda-dropdown-list > div.header {
        background-color: $primary !important;
        font-weight: bold;
        color: $negro-dark2;
    }

    .dda-container .dda-dropdown-list > div.action {
        background-color: #fff !important;
        color: $negro-dark2;
    }

    .dda-container .dda-dropdown-list > div.action:hover {
        background-color: #e6e6e6 !important;
        color: $negro-dark2;
    }

    .dda-container .dda-dropdown-list > div {
        border-bottom: 1px solid #e6e6e6;
    }

    .dda-container .dda-dropdown-item > .flex {
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 17px;
        font-weight: 400;
        padding: 6px;
    }

    /* .dda-dropdown-list > div:hover {
        background: #8eab7d!important;
        color: #f6ffae;
    } */
}

.btn-naranja-drop {
    .show {
        top: 38px !important;
        left: -26px !important;
        width: 200px !important;
    }

    .dda-container .dda-dropdown-list > div.action {
        background-color: #f5f5f5 !important;
        color: $negro-dark2;
    }

    .dda-container .dda-dropdown-list > div.action:hover {
        background-color: #e8e8e8 !important;
        color: $negro-dark2;
    }
    .dda-container .dda-dropdown-item {
        padding: 8px;
    }
    .dda-container .dda-dropdown-item > .flex {
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 17px;
        font-weight: 400;
    }
}

.btn-outline-naranja {
    .show {
        /* top: 38px !important; */
        left: -40px !important;
        width: 200px !important;
    }

    .dda-container .dda-dropdown-list > div.action {
        background-color: #f5f5f5 !important;
        color: $negro;
    }

    .dda-container .dda-dropdown-list > div.action:hover {
        background-color: #e8e8e8 !important;
        color: $negro;
    }
    .dda-container .dda-dropdown-item {
        padding: 8px;
    }
    .dda-container .dda-dropdown-item > .flex {
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 17px;
        font-weight: 400;
    }
}

.btn-icon {
    backface-visibility: hidden;
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    background: $gris-dark;
    border-radius: 100px;
    border: 0px solid #444;
    border-width: 0px 0px 0px 0px;
    color: #fff;
    font-size: 16px;
    font-family: Helvetica Neue;
    font-weight: 900;
    font-style: normal;
    outline-color: transparent !important;
}

.btn-icon-primary {
    background-color: #32a3ff;
}
.btn-icon-primary:disabled {
    cursor: not-allowed;
}

.btn-icon-primary:hover {
    background-color: #226fae;
}

.btn-icon-activo {
    background-color: #226fae;
}

/* TERTIARY BUTTON DROP */
.btn-tertiary-drop {
    color: $negro-dark2;
    background-color: $blanco;
    border: none;
    box-shadow: -2px 3px 0 0 $negro-dark !important;
}

.btn-tertiary-drop:not(:disabled):not(.disabled):active,
.btn-tertiary-drop:not(:disabled):not(.disabled).active,
.show > .btn-tertiary-drop.dropdown-toggle {
    background-color: $negro-dark;
    box-shadow: -2px -3px 0 0 $negro-dark;
    position: relative;
    top: 3px;
    left: -2px;
    transition: top 0.2s ease-in-out;
}

.btn-tertiary-drop:hover {
    border-color: $negro-dark;
    background-color: $negro-dark;
    /* position: relative; */
    color: #fff;
}

.btn-tertiary-drop {
    .show {
        top: 38px !important;
        left: -26px !important;
        width: 200px !important;
    }

    .dda-container .dda-dropdown-list > div.action {
        background-color: #f5f5f5 !important;
        color: $negro-dark2;
    }

    .dda-container .dda-dropdown-list > div.action:hover {
        background-color: #e8e8e8 !important;
        color: $negro-dark2;
    }
    .dda-container .dda-dropdown-item {
        padding: 8px;
    }
    .dda-container .dda-dropdown-item > .flex {
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 17px;
        font-weight: 400;
    }
}

@media only screen and (max-width: 768px) {
    .btn-swal-fracciones:last-child {
        margin-top: 15px;
    }
    .btn-ayuda {
        .show {
            width: 280px;
        }
    }
    .btn-outline-naranja {
        .show {
            bottom: auto !important;
            top: 32px !important;
            height: auto;
            left: -34px !important;
            width: 200px !important;
        }
    }
    /* .btn-ayuda-flotante {
        position: absolute !important;
        top: 5px;
        right: 5px;
    }
    .btn-ayuda-flotante:hover {
        position: absolute !important;
    } */
}

@media only screen and (max-width: 357px) {
    .btn-ayuda {
        .show {
            width: 245px;
        }
    }
}
