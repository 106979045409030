$color-title: #721c24;
$color-navbar: #FFC000;
$input: #878787;
$input-active: #FFFFF;

$naranja-oscuro: #CF6F44;
$naranja-claro: #FEECE6;
$naranja: #FD9261;
$gris: #EFF3F6;
$gris-dark: #B8B8B8;
$blanco: #FFFFFF;
$azul: #299FFF;
$verde: #84BE3E;
$verde-oscuro: #00A200;
$rojo: #DE0000;

$gris-claro: #E2E2E2;

$orange1: #FFA06A;
$orange2: #FC8A64;

$blue1: #006EAA;
$blue2: #00ABFC;

/* COMPRALO */
$primary: #FFC000;
$primary2: #C78B06;
$secondary: #1B69A9;
$secondary2: #051529;
$negro: #29282828;
$negro2: #484848;
$negro-dark: #000000;
$negro-dark2: #515151;


.naranja-oscuro{
    color: $naranja-oscuro;
}
.naranja-claro{
    color: $naranja-claro;
}
.naranja{
    color: $naranja;
}
.negro{
    color: $negro;
}
.negro2{
    color: $negro2;
}
.negro-dark{
    color: $negro-dark;
}
.negro-dark2{
    color: $negro-dark2;
}
.gris{
    color: $gris;
}
.gris-dark{
    color: $gris-dark;
}
.blanco{
    color: $blanco;
}
.azul{
    color: $azul;
}
.verde{
    color: $verde;
}
.verde-oscuro{
    color: $verde;
}
.rojo{
    color: $rojo;
}

.primary {
    color: $primary;
}
.primary2 {
    color: $primary2;
}
.secondary {
    color: $secondary;
}
.secondary2 {
    color: $secondary2;
}
.transparente {
    color: transparent;
}
