@import "./configs/color.css";

.form-control {
    border-radius: 1em;
    border: 0.1em solid $input;
    font-size: 0.8rem;
}
.Select {
    height: 33.33px !important;
}
.Select.is-disabled {
    background-color: #e9ecef !important;
}

.form-control:focus {
    border: 0.1em solid $primary;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.form-control:active {
    border: 0.1em solid $primary;
}

.form-group label {
    margin-top: 0px;
    font-weight: bold;
}

.label-radio {
    padding: 0 10px;
}

/* DATEPICKER VENDEMAS */
.date-picker-vendemas {
    border-radius: 1em;
    input {
        border-radius: 1em;
        border: 0.07em solid $input;
        height: 33px;
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        outline-color: transparent !important;
    }
    input:focus {
        border: 0.1em solid $primary;
        box-shadow: none;
        -webkit-box-shadow: none;
    }
    input:active {
        border: 0.1em solid $primary;
    }
    input:disabled {
        background-color: #e9ecef !important;
    }
}

.date-picker-vendemas-overlay {
    position: absolute;
    background-color: #fff;
    z-index: 1;
    border-radius: 0.5em;
    border: 0.1em solid $input;
}
.DayPicker-Caption > div {
    text-transform: capitalize;
}
.DayPicker-Day {
    padding: 0.2em;
    width: 35px;
    height: 35px;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $secondary;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: $secondary;
}

.compralo-img-popover {
    cursor: pointer;
}

.compralo-img-popover:hover {
    position: absolute;
    margin-left: 29px;
}

.compralo-img-popover span {
    display: none;
}

.compralo-img-popover:hover span {
    position: absolute;
    padding: 10px;
    display: block;
    left: -175px;
    margin: 10px;
    min-width: 350px;
    top: 14px;
    text-decoration: none;
    background-color: #f1c5c5;
    color: #282828;
    min-height: 40px;
    border-radius: 10px;
}
